@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-gap: 32px;
  height: calc(100vh - 64px);

  .contentColumn {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: calc(100vh - 64px);
    height: calc(100vh - 64px);

    .headline {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .titles {
        display: flex;
        flex-direction: column;

        .headCategories {
          display: flex;
          margin-top: 8px;
          align-items: center;

          h6 {
            margin-right: 16px;
          }
        }
      }
    }
  }

  .contentWrap {
    display: flex;
    gap: 24px;
    flex-grow: 1;
    position: relative;
    overflow: auto;

    .chatWrap {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}


.profileModal {
  width: 90vw;
  max-width: 90vw;
  max-height: 80vh;
  height: 80vh;
  position: relative;
}

.bonusWrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  justify-items: center;
  width: 524px;
}

.chatInputWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
  gap: 16px;

  padding: 8px 16px;
  border-radius: 12px;
  background: $line2;
}

.chatInputArea {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.chatAttachmentsWrap {
  display: flex;
  gap: 8px;
  max-width: 500px;
  overflow-x: auto;

  .chatAttachmentWrap {
    position: relative;


    .deleteAttachment {
      position: absolute;
      right: 4px;
      top: 4px;
      background: none;
      border: none;
      opacity: 0;
      transition: .3s;
      cursor: pointer;


      &:hover {
        transform: translateY(-2px);
      }

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: $blue;
        }
      }
    }


    &:hover .deleteAttachment {
      opacity: 1;
    }
  }


  .chatAttachmentCard {
    display: flex;
    height: 75px;
    width: 75px;
    cursor: pointer;
    position: relative;
    background: $white;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .fileName {
      margin-top: 4px;
      word-break: break-word;
    }

    svg {
      height: 40px;
      width: 40px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
}


@keyframes pulse {
  0% {

    scale: (1);
  }

  10% {

    scale: (1.2);
  }
}

.sendIcon {
  display: flex;
  align-items: center;

  svg {
    height: 16px;
    width: 16px;
  }
}
