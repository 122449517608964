@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  justify-items: center;
  width: 100%;
}

.baseCard {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  justify-items: center;
  width: 100%;


  img {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    object-fit: cover;
    background: $blue;
    cursor: pointer;
  }

  .nameWrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
    justify-items: center;
    width: 100%;

  }
}

.stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  width: 100%;


  .statCount {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }
}

.teacherStatusWrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 16px;

  .statusIndicator {
    width: 100%;
    height: 30px;
    padding: 4px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    box-sizing: border-box;


    &.active {
      background: $honeyYellow;
    }

    &.inactive {
      background: $radicalRed;
    }
  }

  .statusButton {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s;


    &.active {
      background: $radicalRed;

      &:hover {
        background: $radicalRedHover;
      }
    }

    &.inactive {
      background: $honeyYellow;

      &:hover {
        background: $honeyYellowHover;
      }
    }
  }
}

.other {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  width: 100%;

  .otherRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background: none;
    border: none;

    &:hover {

      .iconArrow {
        transform: translateX(3px);
      }

      .textWithIcon .iconBg {
        background: $aliceBlueHover;
      }
    }

    .textWithIcon {
      display: flex;
      align-items: center;

      .iconBg {
        height: 30px;
        width: 30px;
        border-radius: 3px;
        background: $aliceBlue;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
      }
    }

    .iconArrow {
      height: 20px;
      width: 20px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;
    }
  }
}


.line {
  background: $lightPink;
  height: 1px;
  width: 80%;
  opacity: .25;
}

.competencyWarningWrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;

  p {
    width: 100%;
    height: 30px;
    padding: 4px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    box-sizing: border-box;
    background: $radicalRed;
    text-transform: uppercase;
  }
}

.registerCompetencyModal {
  width: 524px;
}

.indWrap {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
}




.supportTag {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.suppCounter {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  p {
    color: $lightPink;
    margin-bottom: 4px;
  }
}

.banTag {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 3px;
  background: $radicalRed;
  margin-top: 16px;

  p {
    color: $white;
    text-transform: uppercase;
  }
}

.documentModal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  width: 50vw;
  max-height: 50vh;
  height: 50vh;
  position: relative;

  .docPreview {
    width: 100%;
    background: $line2;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    max-height: 50vh;
    height: 50vh;
    object-fit: cover;
  }

  .documentPhoto {
    max-width: 80vw;
    max-height: 70vh;
  }
}


.documentsWrap{
  display: flex;
  flex-direction: column;
  gap: 16px;
}