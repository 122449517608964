@import "./src/static/colors";

.tableTags {
  display: flex;
  margin-top: -4px;
  flex-wrap: wrap;
  flex-shrink: 0;

  .tag {
    margin-top: 4px;
    margin-right: 4px;
    padding: 4px 8px;
    background: $aliceBlue;
    border-radius: 6px;
    color: $blue;
    word-break: break-all;
  }

  .bigTag{
    margin-top: 4px;
    margin-right: 8px;
    padding: 8px;
    background: $aliceBlue;
    border-radius: 6px;
    color: $blue;
    word-break: break-all;
  }
}


.statusTag {
  padding: 8px 16px;
  border-radius: 3px;
  color: $eerieBlack;
  display: flex;
  justify-content: center;
  max-width: 120px;

  &.yellow {
    background: $honeyYellow;
  }

  &.blue {
    background: $blue;
    color: $white;
  }

  &.red {
    background: $radicalRed;
    color: $white;
  }
}