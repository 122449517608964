@import "./src/static/colors";

.wrap {
  width: 90vw;

  display: grid;
  grid-template-columns: 1fr 1fr;

  max-height: calc(80vh - 48px - 32px - 33px);
  height: calc(80vh - 48px - 32px - 33px);

  .chat {
    height: calc(80vh - 48px - 32px - 33px);
    margin-left: 24px;
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 8px;
    overflow-y: auto;
    max-height: 100%;

    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      margin-bottom: 16px;
    }

    .desc {
      margin-top: 24px;
      display: flex;
      flex-direction: column;

      h6 {
        color: $black2;
        margin-bottom: 8px;
      }
    }

    .rows {
      display: grid;
      grid-template-columns: 1fr;
      margin-left: -8px;
      margin-right: -8px;


      margin-top: 24px;

      .col {
        display: flex;
        flex-direction: column;
        padding: 12px 8px;
        border-radius: 6px;

        h6 {
          margin-bottom: 8px;
          color: $black2;
        }
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        padding: 12px 8px;
        border-radius: 6px;
        transition: .05s;

        .dotline {
          width: 100%;
          border-bottom: 2px dashed $line2;
          min-width: 16px;
          flex-shrink: 1;
        }


        h6 {
          color: $black2;
          flex-shrink: 0;
        }

        p {
          flex-shrink: 0;
        }
      }
    }

    .counters {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;

      margin-top: 24px;

      .counterCard {
        border-radius: 6px;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: $line2;

        &.blue {
          background: $aliceBlue;
        }

        p, h4 {
          color: $blue;
        }

        p {
          margin-right: 16px;
        }

      }
    }
  }
}