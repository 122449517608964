.editModalWrap {
  display: flex;
  flex-direction: column;
  width: 600px;

  .agentList {
    margin-top: 24px;
    min-height: 500px;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .editButtons {
    display: flex;
    gap: 16px;
    align-self: center;
  }
}