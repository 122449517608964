@import "./src/static/colors";

.wrap{
  display: flex;
  width: 100%;

  .content{
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding: 32px;
    box-sizing: border-box;
    width: 100%;
  }
}