@import "./src/static/colors";

.wrap {
  background: $blue;
  width: 150px;
  padding: 16px;
  box-sizing: border-box;
  height: 100vh;
  flex-shrink: 0;
  position: sticky;
  top: 0;


  .navs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-top: 150px;
    padding-bottom: 64px;
    box-sizing: border-box;
  }

  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    box-sizing: border-box;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    color: $aliceBlue;

    svg path {
      fill: $aliceBlue;
    }

    &.active, &:hover {
      background: $aliceBlue;

      .navTitle{
        color: $blue;
      }

      svg path {
        fill: $blue;
      }
    }

    .navTitle {
      margin-top: 8px;
      color: $white;
    }
  }
}