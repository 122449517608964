@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  .table {
    display: grid;
    grid-template-columns: 402px 402px;
    grid-gap: 24px;

    .col {
    }
  }


  .userCard {
    background: $aliceBlue;
    border-radius: 12px;
    padding: 32px;
    display: flex;
    align-items: center;
    width: 402px;

    .photo {
      height: 100px;
      width: 100px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 16px;
      flex-shrink: 0;

      img {
        transition: .3s;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      h6 {
        margin-top: 8px;
        color: $blue;
      }
    }
  }


  .fields {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;

    margin-bottom: 24px;


  }
}
