@import "./src/static/colors";

.wrap {
  display: flex;
  width: 100%;
  max-height: 100%;
  position: relative;
}

.leftBar {
  width: 396px;
  max-height: 100%;
  position: sticky;
  top: 97px;
  z-index: 1000;
  overflow: auto;


  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .headerBlock {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    align-items: center;

    .role {
      color: $blue;
      margin-top: 8px;
    }
  }

  .summaryGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    margin-top: 16px;
    margin-bottom: 32px;

    .summaryBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      text-align: center;

      .rating {
        display: flex;
        align-items: center;

        p {
          margin-left: 4px;
        }
      }
    }
  }

  .photo {
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background: $aliceBlue;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .infoList {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    padding-top: 24px;
    border-top: 1px solid $line2;

    .infoOption {
      display: flex;
      flex-direction: column;
    }

    .optionHeader {
      color: $text2;
      margin-bottom: 4px;
    }
  }

  .showDropdown {
    margin-right: 8px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    .dropdownButton {
      display: flex;
      align-items: center;
      cursor: pointer;

      .arrowContainer {
        transition: .3s;

        &.rotated {
          transform: rotate(180deg);
        }
      }
    }

    .changeButton {
      color: $blue;
      transition: .2s;
      background: $aliceBlue;
      padding: 8px 16px;
      border-radius: 6px;
      cursor: pointer;

      border: none;


      &:hover {
        background: $aliceBlueHover;
      }
    }
  }
}

.rightPanel {
  margin-left: 32px;
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  gap: 32px;

  .contentWrap {
    display: flex;
    flex-direction: column;

    img {
      width: 60%;
      opacity: .9;
    }
  }
}

.navHeader {
  display: flex;
  align-items: center;

  .backButton {
    margin-right: 16px;
    cursor: pointer;
    height: 40px;
    width: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}










