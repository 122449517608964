@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ticketInfoWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  width: 400px;
  padding-right: 8px;
  flex: 1;

  .flexBlock {
    display: flex;
    flex-direction: column;

    h6 {
      color: $lightPink;
      margin-bottom: 8px;
    }

    .infoRows {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .infoRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .infoCol {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .infoBanner {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 12px;
    color: $black2;
    padding: 8px 16px;
    box-sizing: border-box;

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%233F4254FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='20' stroke-linecap='butt'/%3e%3c/svg%3e");
  }
}

.reviewBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;


    .rate {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
