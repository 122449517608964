@import "./src/static/colors";

body, div {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

a {
  color: #1A1A1A;
  text-decoration: none;
}


.recharts-default-legend {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: none;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar-track {
  background: none;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: $blue;
  transition: .3s;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $aquaBlueHover;
}