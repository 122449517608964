
.chatsColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow: auto;

  .chatListWrap {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    height: 100%;
    overflow: auto;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
