@import "./src/static/colors";

.wrap {
  display: flex;
  flex-direction: column;

  .header {
    display: grid;
    background: $line2;
    grid-gap: 16px;

    padding: 32px 16px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;

    p {
      color: $tableHeader;
      text-transform: uppercase;

    }
  }

  .rows {
    display: flex;
    flex-direction: column;
  }
}

.row {
  display: grid;
  grid-gap: 16px;
  padding: 16px;
  align-items: center;

  &:nth-child(2n) {
    background: $line2;
  }

  &:last-child {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}