@import "./src/static/colors";

.wrap{
  display: flex;
  flex-direction: column;
}

.newTicketButton{
  width: 100%;
  margin-bottom: 32px;
}

.chartWrap{
  position: relative;
  height: 300px;
}

.chartHeaderWrap{
  padding-bottom: 16px;
  position: relative;

  .chartHeader{
    display: flex;
    justify-content: flex-end;
    align-items: center;


    svg{
      transition: .3s;
      cursor: pointer;

      &:hover{
        transform: scaleY(.8);
      }
    }
  }

  .chartOptionsWrap{
    background: $white;
    border-radius: 6px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: calc(100% + 8px);
    z-index: 1000;
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
    transition: .3s;

    border: 1px solid #E6F1FF;
    box-sizing: border-box;
    box-shadow: 0 11px 22px -12px rgba(60, 74, 228, 0.25);

    &.visible{
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }

    .chartOption{
      padding: 8px 16px;
      transition: .3s;
      cursor: pointer;

      &:hover{
        background: $line2;

      }
    }
  }
}