@import "./src/static/colors";

.tableRow {
  display: grid;
  grid-template-columns: 100px 5fr 5fr 3fr 3fr 3fr 3fr 91px;
  align-items: center;
  grid-gap: 24px;
  padding: 16px;
  border-bottom: 1px solid $line2;

  &:nth-child(2n) {
    background: $line2;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .infoWrap {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
  }


  .status {
    p {
      padding: 4px 8px;
      width: 80px;
      display: flex;
      justify-content: center;
      border-radius: 6px;

      &.active {
        background: $honeyYellow;
      }

      &.cancelled {
        background: $lightPink;
      }

      &.done {
        background: $aliceBlue;
      }
    }
  }
}

.tagContainer {
  display: flex;
  margin-top: -4px;
  flex-wrap: wrap;

  .tagWrap {
    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $blue;
      background: $aliceBlue;
      border-radius: 6px;
      padding: 8px;
      margin-top: 4px;
      margin-right: 4px;
      max-height: max-content;
    }
  }
}

.status {
  padding: 8px 16px;
  border-radius: 3px;
  color: $eerieBlack;
  display: flex;
  justify-content: center;

  &.yellow {
    background: $honeyYellow;
  }

  &.blue {
    background: $blue;
    color: $white;
  }

  &.red {
    background: $radicalRed;
    color: $white;
  }
}





