@import "./src/static/colors";

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 90vw;
  max-height: calc(80vh - 48px - 32px - 33px);
  height: calc(80vh - 48px - 32px - 33px);

  .chat {
    height: calc(80vh - 48px - 32px - 33px);
    margin-left: 24px;
  }

  .infoColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 8px;
    overflow-y: auto;
    max-height: 100%;
  }

  .users {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    margin-bottom: 16px;
  }

  .infoRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }

  .stats {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-top: 24px;

    .infoColumn {
      gap: 8px;
    }

    h6 {
      color: $black2;
    }
  }

  .tagContainer {
    display: flex;
    margin-top: -4px;
    flex-wrap: wrap;

    .tagWrap {
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $blue;
        background: $aliceBlue;
        border-radius: 6px;
        padding: 4px 8px;
        margin-top: 4px;
        margin-right: 4px;
        max-height: max-content;
      }
    }
  }
}

.attachmentWrap {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .attTabs {
    display: flex;
    margin-bottom: 12px;

    .tabButton {
      background: none;
      border: none;
      margin-right: 16px;
      transition: .3s;
      cursor: pointer;
      position: relative;

      &:after {
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 1px;
        width: 100%;
        background: $blue;
        content: "";
        opacity: 0;
        transform: scaleX(0);
        transition: .3s;
      }


      &:active {
        transform: translateY(2px);
      }

      &.active {
        color: $blue;

        &:after {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }
  }

  .attFiles {
    display: flex;
  }
}

.modalImg {
  max-width: 600px;
  max-height: 75vh;
  object-fit: contain;
  width: 100%;

}

.modalImgPrev {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 8px;
  cursor: pointer;
  background: $line2;
  border: none;

  img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
}

.emptyCard {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $line2;
  border-radius: 6px;
}

.reviewsWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;

  margin-top: 24px;
}

.reviews {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;


  .reviewBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .titleRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;


      .rate {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}