@import "./src/static/colors";

.chatListCardWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 8px;
  cursor: pointer;
  position: relative;


  .newIndicator {
    height: 16px;
    width: 16px;
    background: $blue;
    border-radius: 100%;

    position: absolute;
    right: 8px;
    top: 16px;

    animation: pulse 2s infinite ease-in-out;
  }

  &:not(:last-child) {

    &:after {
      content: "";
      position: absolute;
      width: 80%;
      height: 1px;
      background: $line2;
      left: 10%;
      bottom: 0;
    }
  }

  &.active {
    background: $line2;
  }


  .infoWrap {
    display: flex;
    flex-direction: column;
  }

  .userPhoto {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background: $line2;
    margin-right: 16px;
  }
}
