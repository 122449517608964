@import "./src/static/colors";


.userWrap {
  padding: 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background: $line2;


  .mainInfo {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 100px;
      width: 100px;
      border-radius: 100%;
      background: $aliceBlueHover;
      object-fit: cover;
    }
  }

  .userInfoWrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    width: 100%;
    margin-left: 16px;

    .row {
      display: flex;


      justify-content: space-between;
      align-items: center;
    }

    .ratingWrap {
      display: flex;
      align-items: center;

      p {
        margin-left: 4px;
      }
    }


    .role {
      color: $blue;
    }

    .rowWithIcon {
      display: flex;
      align-items: center;

      svg{
        width: 20px;
        height: 20px;
      }

      p {
        margin-left: 8px;
      }
    }
  }

}

.notFound {
  width: 100%;
  text-align: center;
}