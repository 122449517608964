@import "./src/static/colors";

.wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;

  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
  }

  .preloaderCard{
    background: $line2;
    border-radius: 12px;
    height: 175px;
  }

  .table {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 324px;
    grid-gap: 24px;
    height: 100%;
  }

  .chart {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.emptyTicketList {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: $line2;
  border-radius: 12px;
}
