
.tableWrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
}

.wrap {
  display: flex;
  flex-direction: column;

  .searchBar {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
  }
}